import React from "react";
import { CopySection, NextArrow } from "@molecules";
import { Container } from "@atoms";

const Challenge = ({ heading, copy, next }) => {
  return (
    <Container>
      <CopySection heading={heading} copy={copy} className="text-white" />
      <div className="mt-10 flex min-h-[15vh] flex-col sm:min-h-[33vh] sm:flex-row">
        <NextArrow next={next} />
      </div>
    </Container>
  );
};

export default Challenge;
